// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Open+Sans|Titillium+Web:400,700');

// Variables
@import "variables";

@mixin button {
    border: solid 1px #eaeaea !important;
    border: none;
    background-color: #18537d !important;
    padding: 0.8em 1.5em;
    border-radius: 1px;
    cursor: pointer;
    font-size: 13px;
    font-weight: lighter;
    margin-bottom: 1.5em;
    color: #fff;
    display: inline-block;
}

@mixin button-sec {
    border: solid 1px #eaeaea !important;
    border: none;
    background-color: #2f7ed8 !important;
    padding: 0.8em 1.5em;
    border-radius: 1px;
    cursor: pointer;
    font-size: 13px;
    font-weight: lighter;
    margin-bottom: 1.5em;
    color: #fff;
    display: inline-block;
}

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/** ------------------------------------------------------ **/
/** -------------------2 column layout -------------------- **/

.trExpired {
    background-color: #D84646;
}

.trOkay {
    background-color: #3CB371;
}

.trNear {
    background-color: #FFBF00;
}

body {
    background-color: #fff;
    color: #727375;
    font-family: 'Lato', Verdana, Helvetica, sans-serif;
    font-size: 0.75em;
    margin: 0px;
    text-align: center;

    &.login {
        .newLogo {
            float: none;
            width: 100%;
            text-align: center;
            padding-top: 2em;
        }
    }
}

h2 {
    color: #212121 !important;
    font-size: 1.3em !important;
    font-weight: bold !important;
    font-family: 'Open Sans', sans-serif;
}

#skip-navigation {
    height: 1px;
}

#wrap {
    background-color: #FFFFFF;
    margin: 0 auto;
    text-align: left;
    width: 100%;
    position: relative;
}

#header {
    /*	height: 207px;
            position: relative;
            z-index: 3; */
}

#centerc {
    display: block;
    float: none;
    margin: 0 auto;
    padding: 3em 3em;
}

#foot {
    clear: both;
    font-size: 0.9em;
    border-top: 20px solid #fff;
    // padding-bottom: 10px;
}

.adcontainer {
    height: 183px;
    width: 296px;
    margin-left: 68%;
    margin-top: -228px;
    padding-bottom: 20px;
}

/** ------------------------------------------------------ **/
/** ------------------- header --------------------------- **/

/** ------------------------------------------------------ **/
/** ------------------- charts accordian --------------------------- **/


#accord-wrapper {
    margin-top: 20px;

}

.accord-button {
    width: 942px;
    float: left;
    background: #005795;
    border-bottom: 1px solid #FFFFFF;
    cursor: pointer;
    color: #fff;
    padding: 3px;


}

.accord-content {
    width: 942px;
    float: left;
    background: #fff;
    display: none;
    padding: 3px;

}

/***********************************************************************************************************************
 EXTRA STYLES ADDED FOR MOUSEOVER / ACTIVE EVENTS
************************************************************************************************************************/

.on {
    background: #005795;
}

.over {
    background: #CCCCCC;
}

/** ------------------------------------------------------ **/
/** ------------------- header --------------------------- **/



#header_quote {
    float: right;
}

.headerwrap {
    margin: 0 auto;
    padding: 3em;
}

.logout a {
    background-color: #18537d;
    color: #fff !important;
    border: none;
    padding: 0.8em 1.5em;
    border-radius: 1px;
    cursor: pointer;
    font-size: 13px;
    font-weight: lighter;
}

.configure-link a {
    @include button-sec;
}

.newLogo {
    float: left;
    width: 600px;

    h1 {
        font-size: 4em !important;
        font-family: 'Titillium Web', sans-serif;
        color: #2f7ed8;
        margin: 0px;
        font-weight: bold;
        line-height: 1;
    }

    h2 {
        color: #2d2d2d !important;
        font-size: 14px !important;
        margin: 8px 0px 0px 0px;
    }

    img {
        float: left;

    }
}

#header_graphic {
    right: 0px;
    position: absolute;
    top: 0px;
}

#header_graphic img {
    display: block;
    /*    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(...);*/
}

#navigation {
    background-color: $brand-main;
    background-repeat: repeat-x;
    clear: both;
    font-size: 1.2em;

    a,
    a:visited {
        color: #fff;
        font-size: 0.9em;
        font-weight: normal;
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif;
    }

    ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        display: table;

        li {
            display: inline-block;
            padding: 1.4em 1em;
            position: relative;

            &:hover ul {
                display: block
            }
        }

        ul {
            position: absolute;
            background: #1a4869;
            display: none;
            left: 0px;
            top: 100%;
            white-space: nowrap;
            z-index: 10000;
            width: 200px;

            li {
                display: block;
                padding: 0px;
                transition: 0.2s;

                &:hover {
                    background-color: #18415f;
                }

                a {
                    padding: 10px 15px;
                    display: block;
                }
            }
        }
    }

}


/** ------------------------------------------------------ **/

/** ------------------------ centercontent --------------------- **/

body.training-matrix {
    #centercContent {
        >p {
            display: inline-block;
        }

        p>a {
            background-color: #18537d;
            color: #fff !important;
            border: none;
            padding: 0.8em 1.5em;
            border-radius: 1px;
            cursor: pointer;
            font-size: 13px;
            font-weight: lighter;
            display: inline-block;
        }
    }
}



/** ------------------------------------------------------ **/

body.settings {
    form.filter {
        margin-bottom: 3em;
        padding-bottom: 3em;
        border-bottom: solid 2px #eaeaea;
    }
}


/** ------------------- footer --------------------------- **/
#footer {
    background-color: #005795;
    color: #fff;
    padding: 3em;
    padding-left: 20px;
    padding-right: 0px;
}

#footerleft {

    margin: 0 auto;

    div {
        display: inline-block;
        width: 49.5%;

        &:first-of-type {
            font-style: italic;
            color: #6ba7d2;
        }

        &:last-of-type {
            text-align: right;
        }
    }
}

#footer a,
#footer a:visited {
    color: #fff;
    font-weight: normal;
}

#footer a:hover {
    color: #ccc;
    font-weight: normal;
}

#footerright {
    float: right;
}

#footer .strapline {
    font-size: 1.8em;
    padding-bottom: 10px;
    padding-top: 10px;
}

/** ------------------------------------------------------ **/
/** ------------------- HTML elements -------------------- **/


img {
    border: 0px;
}

caption {
    font-weight: normal;
    text-align: left;
}

th {
    font-weight: bold;
    text-align: left;
}

pre {
    font-size: 16px;
    background-color: #000;
    color: greenyellow;
    padding: 10px;
    text-align: left;
}



/** ------------------------------------------------------ **/
/** ------------------- form ------------------------- **/

#contact_us form {
    margin: 0px;
    padding: 0px;
}

#contact_us fieldset {
    border: 0px;
    padding: 0px;
    width: auto;
}

#contact_us legend {
    display: none;
}

#contact_us input {
    border: 1px solid #cdcdcd;
    padding: 3px;
    width: 255px;
}

#contact_us textarea {
    border: 1px solid #cdcdcd;
    margin-bottom: 3px;
    padding: 3px;
    width: 255px;
}

#contact_us select {
    margin-right: 10px
}

#contact_us input.buttons {
    color: #000000;
    float: none;
    font-weight: normal;
    width: 75px;
}

#contact_us input.radiobuttons {
    width: auto;
}

#contact_us form fieldset div div {
    padding-bottom: 0px;
    overflow: auto;
}

#contact_us form fieldset p {
    padding-bottom: 10px;
    clear: both;
}

#contact_us label {
    display: block;
    font-weight: bold;
    padding-bottom: 5px;
    padding-top: 10px;
}



#docCreate {
    float: right;
    margin: 22px
}

#docCreate input {
    border: 1px solid #cdcdcd;
    padding: 3px;
    width: 255px;
}

#docCreate input.buttons {
    color: #000000;
    float: none;
    font-weight: normal;
    width: 75px
}

#docCreate label {
    margin-right: 40px;
    font-weight: bold;
}

/** ------------------------------------------------------ **/
/** ------------------- div table replacements ----------- **/

/*create a row*/
div.TableReplaceRow {
    clear: both;
    padding-top: 0px;
}

/*label cell*/
div.TableReplaceRow div.TableReplaceCell {
    float: left;
    text-align: left;
    width: 200px;
    margin-right: 20px;
}

/*data cell*/
div.TableReplaceRow div.TableReplaceCellForm {
    float: left;
    width: 380px;
}

/*data cell*/
div.TableReplaceRow div.TableReplaceRight {
    float: right;
    margin: 0px;
}

div.TableReplaceRowClear {
    clear: both;
}

.clearboth {
    clear: both;
}

/** ------------------------------------------------------ **/
/** ------------------- Loginform ------------------------ **/


#divLoginForm {
    float: right;
    width: 450px;
}

#divLoginForm h2 {
    font-size: 1.4em;
    margin-bottom: 10px;
}

#divLoginForm form fieldset div {
    clear: none;
    float: left;
    padding-bottom: 0px;
}

#divLoginForm label {
    display: block;
    float: left;
    width: auto;
    margin-right: 10px;
}

#divLoginForm input {
    border: 1px solid #000000;
    float: left;
    margin-right: 10px;
    padding: 3px;
    width: 100px;
}

#divLoginForm input.buttons {
    color: #000000;
    float: left;
    font-weight: normal;
    margin-right: 0px;
    padding: 1px;
    width: auto;
}



.login-wrap {
    text-align: center;
    overflow: auto;
    margin: 0 auto;
    width: 45%;
    padding: 8% 0%;

    >div {
        text-align: left;

        h1 {
            color: #1f1f1f !important;
        }

        input.buttons {
            background-color: #18537d;
            color: #fff !important;
            border: none !important;
            padding: 0.8em 1.5em !important;
            border-radius: 1px;
            cursor: pointer;
            margin-top: 1em;
            width: auto !important;
        }

        input[type="text"] {
            padding: 6px !important;
        }

        input[type="password"] {
            padding: 6px !important;
        }
    }

    .login-left {
        width: 50%;
        float: left;
        vertical-align: bottom;
        box-sizing: border-box;
        padding: 3em;
        border: solid 2px #eaeaea;
        border-right: none;
        height: 385px;
    }

    .login-right {
        width: 50%;
        float: right;
        vertical-align: bottom;
        box-sizing: border-box;
        padding: 3em;
        border: solid 2px #eaeaea;
        height: 385px;
    }
}

/** ------------------------------------------------------ **/
/** ------------------- clients -------------------------- **/

#divStoppress {
    border: solid 2px #eaeaea;
    float: left;
    /* margin-top: 11px; */
    overflow: auto;
    padding: 30px;
    width: 23.5%;
    height: auto;
    box-sizing: border-box;
}

* html #divStoppress {
    /* This is the Tan hack */
    width: 296px;
    w\idth: 274px;
    height: 704px;
    he\ight: 682px;
}

#divClientIntro #divClientIntroLogo {
    float: left;
    margin-bottom: 20px;
    margin-right: 20px;
    width: 294px;
}

#divClientIntro #divClientIntroPara {
    float: left;
    width: 548px;
    /*margin-bottom: 20px;
    margin-right: 22px;*/
}

#divClientIntro #divClientIntroPara h2 {
    margin-top: 0px;
    margin-bottom: 0px;
}

#divClientIntro {
    float: right;
    width: 75%;
}

#divClientIntro #divClientIntroPara p {
    margin-top: 0px;
    margin-bottom: 10px;
}

#divClientButtons {
    float: none;
    width: 100%;
    clear: both;
    overflow: auto;
}

#divClientButtons+#divClientButtons {
    margin-top: 30px;
}

#divClientSearch {
    float: right;
    margin-right: 22px;

    input[type="text"] {
        border: 1px solid #cdcdcd;
        padding: 3px;
        padding: 0.4em;
        width: 210px;
    }

    input[type="submit"] {
        background-color: $brand-main;
        color: #fff;
        border: none;
        padding: 0.5em 1em;
        cursor: pointer;
    }
}

div.divClientCategoriesRow {
    clear: both;
}

/*label cell*/
div.divClientCategoriesRow div.divClientCategoriesCell {
    border: 1px solid #ccc;
    float: left;
    padding: 2em;
    margin-bottom: 10px;
    margin-right: 1%;
    text-align: center;
    width: 32%;
    box-sizing: border-box;
    font-size: 13px;
    transition: 0.2s;

    br {
        display: none;
    }

    &:hover {
        background-color: $brand-main;
        border: 1px solid $brand-main;

        a {
            color: #fff;
        }
    }
}

#divClientButtons {
    &:last-of-type {
        margin-top: 3em;
        padding-top: 3em;
        border-top: solid 2px #eaeaea;
    }
}

* html div.divClientCategoriesRow div.divClientCategoriesCell {
    /* This is the Tan hack */
    width: 176px;
    width: 154px;
    height: 50px;
    height: 30px;
}

/*link colour*/
div.divClientCategoriesRow div.divClientCategoriesCell a,
div.divClientCategoriesRow div.divClientCategoriesCell a:visited {
    color: #3c3d3e;
    display: block;
    height: 100%;
    margin: -10px;
    padding: 10px;
    width: 100%;
}

/*rollover*/
div.divClientCategoriesRow div.divClientCategoriesCell a:hover {
    color: #fff;
}

/*link colour*/
div.divClientCategoriesRow #divHelp a,
div.divClientCategoriesRow #divHelp a:visited {
    color: #727375;
}

/*rollover*/
div.divClientCategoriesRow #divHelp a:hover {
    color: #9daf23;
}

div.colour1 {
    background-color: #ff0000;

    a {
        color: #fff !important;
    }
}

div.colour2 {
    background-color: #fff;
}

div.colour3 {
    background-color: #fff;
}

div.colour4 {
    background-color: #fff;
}

div.colour5 {
    background-color: #fff;
}

div.colour6 {
    background-color: #fff;
}

div.colour7 {
    background-color: #fff;
}

div.colour8 {
    background-color: #fff;
}

div.colour9 {
    background-color: #fff;
}

div.colour10 {
    /*background-color: #005795;*/
    background-color: #fff;
}

.formSearch {
    margin-bottom: 10px;
}

.formSearch input.buttons {
    padding: 1px;
}

/** ------------------------------------------------------ **/
/** ------------------- accident form ------------------------- **/

#formAccident {
    margin: 0px;
    padding: 0px;
}

#formAccident div+fieldset,
#formAccident fieldset+fieldset {
    margin-top: 15px;
}

#formAccident fieldset {
    padding: 10px;
    padding-top: 10px;
    width: auto;
}

#formAccident legend {
    font-weight: bold;
    padding-bottom: 10px;
}

#formAccident input {
    float: left;
    margin-bottom: 15px;
    margin-top: 0px;
}

.center {
    text-align: center;
}

#formAccident input[type=checkbox] {
    margin: 0 auto;
    float: none;
}

#formAccident th input[type=checkbox] {
    position: relative;
    top: 2px;
}

#formAccident tbody td input[type=checkbox] {
    position: relative;
    top: -1px;
}

#formAccident th {
    background: #EEE;
}

#formAccident table {
    width: 100%;
}

#formAccident select {
    margin-bottom: 15px;
}

#formAccident textarea {
    width: 600px;
    min-height: 60px;
    margin-bottom: 15px;
}

#formAccident input.buttons {
    float: none;
}

#formAccident fieldset div {
    padding-bottom: 0px;
    clear: both;
}

#formAccident label {
    display: block;
    float: left;
    width: 150px;
    margin-right: 10px;
    padding-bottom: 10px;
}

#formAccident label.labellong {
    display: block;
    float: left;
    width: 230px;
    margin-right: 20px;
    padding-bottom: 10px;
}

#formAccident label.labelvlong {
    display: block;
    float: left;
    width: 650px;
    margin-right: 20px;
    padding-bottom: 10px;
}

#formAccident label.block {
    margin-left: 160px;
    clear: both;
}

#formAccident label.inline {
    width: auto;
}

#formAccident p.Padd {
    float: left;
    padding-top: 10px;
}

#formAccident p a {
    float: left;
    margin-top: 3px;
    margin-left: 4px;
}

#feedbacklogout {
    position: relative;
    top: 25px;
    margin-left: 250px;
}

/** ------------------------------------------------------ **/
/** ------------------- filter form ---------------------- **/

form.filter {
    margin: 2em 0em 2em 0em;
    padding: 0px;
}

form.filter+table {
    margin-top: 20px;
}

form.filter fieldset {
    padding-left: 5px;
    border: solid 2px #eaeaea;
    padding-top: 1em;
}

form.filter legend {
    font-weight: bold;
    padding-bottom: 0px;
    padding-left: 6px;
    background-color: #fff;
    padding-right: 6px;
}

form.filter fieldset div {
    display: inline-block;
    float: none;
    padding-bottom: 0px;
}

form.filter fieldset div.FilterDiv1of2 {
    display: inline-block;
    float: none;
    margin: 0em 0.5em;
}

form.filter fieldset div.FilterDiv2of2 {
    display: inline-block;
    float: none;
    margin: 0em 0.5em;
}

form.filter fieldset div.FilterDivButton {
    input {
        @include button;
    }
}

form.filter label {
    display: block;
    margin-bottom: 3px;
}

form.filter select {
    min-width: 120px;
    padding: 6px 2px;
}

/*forms*/

form.form label.label {
    width: 250px;
    display: inline-block
}

form.form div {
    margin: 10px 0
}


form.form input.emplyfmradio {
    width: 60px
}

form.form label.emplyfmlabel {
    width: 67px;
    text-align: center
}

/** ------------------------------------------------------ **/
/** ------------------- calendar --------------------------- **/
.hidden {
    opacity: 0;
    position: absolute;
}

.visible {
    opacity: 1;
}

button.calendar {
    background-color: #FFFFFF;
    background-image: url(/admin/images/calendar.gif);
    background-repeat: no-repeat;
    border: 0;
    cursor: pointer;
    float: left;
    height: 20px;
    width: 24px;
}

/*button.calendar:hover, button.calendar.active {
        background-position: 0 20px;
}*/

div.calendar {
    background-color: #FFFFFF;
    border: 1px solid #999999;
    padding: 5px;
    text-align: center;
    width: 147px;
}

div.calendar * {
    margin: 0;
    padding: 0;
}

div.calendar div {
    background: none !important;
    cursor: move;
    overflow: hidden;
    position: relative;
    width: 147px;
}

div.calendar caption {
    color: #666666;
    /*font: normal 12px/16px Arial, Helvetica, sans-serif;*/
    padding-bottom: 5px;
    text-align: center;
    width: 100%;
}

div.calendar caption a {
    cursor: pointer;
    display: block;
    height: 12px;
    overflow: hidden;
    position: absolute;
    text-indent: -100px;
    top: 4px;
    width: 11px;
}

div.calendar caption .year a {
    top: 18px
}

div.calendar caption a.prev {
    background-image: url(/admin/images/calendar-prev.gif);
    background-repeat: no-repeat;
    left: 0;
}

div.calendar caption a.next {
    background-image: url(/admin/images/calendar-next.gif);
    background-repeat: no-repeat;
    right: 0;
}

/*div.calendar caption a:hover {
                background-position: 0 12px;
        }*/
div.calendar caption span {
    /*height: 25px;
    position: relative;*/
    text-align: center;
    display: block;
    width: 100%
}

div.calendar caption span.month {
    padding-right: 8px;
}

/*
div.calendar caption span.month:after {
        content: ',';
}*/

div.calendar table {
    background: #FFFFFF;
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    cursor: default;
    margin: 0 auto;
    overflow: hidden;
    width: 147px;
}

div.calendar td,
div.calendar th {
    color: #666666;
    border: 0;
    height: 21px;
    font-size: 11px;
    text-align: center;
    width: 21px;
}

div.calendar th {
    border-bottom: 1px solid #CCCCCC;
    font-weight: normal;
}

div.calendar td {}

div.calendar td.invalid {
    color: #CCCCCC;
}

div.calendar td.valid {
    color: #000000;
    cursor: pointer;
}

div.calendar td.hilite {}

div.calendar td.inactive {}

div.calendar td.active,
div.calendar td.hover {
    background-color: #cccccc;
    color: #FFFFFF;
    cursor: pointer;
}

/** ------------------------------------------------------ **/
/** ------------------- bespoke -------------------------- **/

#divHomePic {
    position: relative;
    z-index: 1;
}

#divHomeText {
    position: absolute;
    top: 280px;
    left: 650px;
    width: 270px;
    z-index: 2;

    color: #FFFFFF;
    font-size: 1.2em;
    text-align: left;

}

div.divAlterBlockMargins h1,
div.divAlterBlockMargins h2,
div.divAlterBlockMargins p,
div.divAlterBlockMargins blockquote {
    margin-top: 0px;
    margin-bottom: 0px;
}

div.divAlterBlockMargins hr {
    margin-top: 1em;
    margin-bottom: 1em;
}

tr.trZebra {
    background-color: #f9f9f9;
}

table.stripey tbody tr:nth-child(odd) {
    background-color: #ecedee;
}

.tableAccident tr,
.tableAccident td {
    vertical-align: top;
}


div.TableReplaceRow div.TableReplaceCellServiceIndustry {
    float: left;
    text-align: left;
    width: 376px;
    margin-right: 20px;
}

div.TableReplaceRow div.TableReplaceCellServiceSubject1 {
    float: left;
    text-align: left;
    width: 253px;
    margin-right: 20px;
}

div.TableReplaceRow div.TableReplaceCellServiceSubject2 {
    float: left;
    text-align: left;
    width: 221px;
    margin-right: 0px;
}

.plannerTDResults {
    border-right: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    text-align: center;
    width: 50px;
}

.TDResults {
    a {
        background-color: $brand-main;
        color: #fff;
        padding: 6px 12px;
        margin-bottom: 0px;
        display: inline-block;
    }
}




/** ------------------------------------------------------ **/
/** ------------------- category ------------------------- **/

.page-category .section {
    display: block;
    margin-top: 15px;
    padding: 0 0 5px 0;
    font-weight: bold;
    font-size: 1.3em;
    color: #234792;
    border-bottom: 1px solid #D8D9D9;
}

.page-category .section+div {
    margin: -10px 0 10px 0;
    padding-top: 10px;
}

.page-category .row h2,
.page-category .row p {
    margin: 0;
}

.page-category .row h2 em {
    font-weight: normal;
}

.page-category .row p+p {
    margin-top: 5px;
}

.page-category .row {
    padding: 10px 6px;
    min-height: 35px;
}

.page-category .row:hover {
    background: #F5F5F5;
}

.page-category .row img {
    margin-bottom: 0;
    margin-right: 5px;

    border: 5px solid #fff;
    border-radius: 3px;
}

/* JS enabled */
.advanced .page-category .section {
    padding-left: 15px;
    background: url(../images/layout/e-ses/arrows.gif) no-repeat 0 -16px;
}

.advanced .page-category .section.open {
    background-position: 0 4px;
}

.advanced .page-category .section+div {
    display: none;
}

.advanced .page-category div.open {
    display: block;
}







.hide {
    display: none
}





/*hr.php and poa.php*/
#hrNavBtns {
    float: left;
    width: 230px;
    margin-top: 30px;
    min-height: 300px
}

#hrNavBtns>a {
    background: #004e8a;
    color: white;
    display: block;
    margin-bottom: 10px;
    padding: 15px 10px;
    text-align: center;
    width: 154px;
}

#hrNavBtns div div {
    float: left;
    margin-bottom: 10px;
    padding: 4px 10px;
    width: 114px;
    background: #ddd;
}

#hrNavBtns div div:nth-child(2) {
    width: 20px
}

#hrEmployeesList {
    float: left;
    width: 640px
}

select#location,
select#department,
input#employeeSearch {
    margin: 0 0 20px 20px;
    width: 300px;
    padding: 4px;
}







/*employee-view.php*/
#profileDetails {
    float: left;
    width: 755px
}

#profileDetails table th:nth-child(1) {
    width: 150px
}

#profileDetails table td:nth-child(1) {
    width: 150px
}

#profileDetails table th:nth-child(2) {
    width: 450px
}

#profileNav {
    float: right;
    min-height: 420px;
    width: 182px
}

/*<-- specially for you internet explorer*/
#profileNav img {
    width: 180px;
    height: 180px
}

#profileNav div a div {
    height: 30px;
    margin-bottom: 3px;
    background: #004E8A;
    color: white;
    padding: 14px 0 0 7px
}

#profileNav div a div div {
    width: 20px;
    float: right
}

#profileNav div a:nth-child(2) div {
    width: 20px;
    float: right
}

#profileNav div a:nth-child(1) div:nth-child(1) {
    width: 145px;
    float: left
}

#profileNav div a:nth-child(1) div div:nth-child(1) {
    width: 20px;
    float: right;
    padding: 0 5px 0 0
}

#profileDetails+div img {
    border: 1px solid #004E8A
}

#profileDetails .categoryHead,
#profileDetails .title {
    background: #004E8A;
    color: white;
    padding: 8px;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 3px 0;
}

#profileDetails .subcategoryHead {
    background: #EBEBEB;
    color: #004E8A;
    padding: 7px 8px 7px 30px;
    font-weight: bold;
    font-size: 15px;
    margin: 0 0 3px 0;
}

#profileDetails .document {
    padding: 12px 15px 12px 60px;
    font-weight: bold;
    font-size: 14px;
}

#profileDetails .document img {
    height: 25px
}

#profileDetails .colapsed .categoryHead:before,
#profileDetails .subcategory.colapsed .subcategoryHead:before {
    content: '\0025b7\000020'
}

#profileDetails .expanded .categoryHead:before,
#profileDetails .subcategory.expanded .subcategoryHead:before {
    content: '\0025e2\000020'
}

/*
▲  \0025b2
△  \0025b3
▷  \0025b7
►  \0025ba
▼  \0025bc
▽  \0025bd
◁  \0025c1
◄  \0025c4
◆  \0025c6
◇  \0025c7
◢  \0025e2
◣  \0025e3
◤  \0025e4
◥  \0025e5
*/
#profileDetails .colapsed .docContainer,
#profileDetails .colapsed .subcategory,
#profileDetails .subcategory.colapsed .docContainer {
    display: none;
}

#profileDetails .title div {
    float: right;
}

#profileDetails .title div a {
    color: white
}


/*training-matrix-form.php*/
.column {
    display: inline-block;
    padding: 2%;
    vertical-align: top;
    width: 45%;
}


/* Red and Green text login messages */

.login-wrap {

    .redtext,
    .greentext {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        color: #fff !important;
        padding: 1em;
        margin-bottom: 1em;
    }

    .redtext {
        background-color: #D32F2F;
    }

    .greentext {
        background-color: #4caf50;
    }
}


/*employee-form.php*/
#formAccident [name^="gender"] {
    float: none
}






/*poa-form.php*/
#formPoa input[type=text],
#formPoa select {
    width: 200px;
    margin: 20px 0
}

#formPoa textArea {
    width: 617px;
}

#formPoa label {
    display: inline-block;
    margin: 0 0 20px 40px;
    width: 135px;
}

#formPoa textarea {
    margin-top: 20px;
}

#formPoa button.calendar {
    float: none;
    margin: 0 -30px 0 0;
    padding-top: 10px
}

#formPoa .radios label {
    width: 210px
}

#formPoa .radios input,
#formPoa input[type=checkbox] {
    margin: 20px 4px
}

#formPoa hr {
    margin: 40px 0
}



/*jqueryui combobox*/
.ui-button {
    height: 18px;
    margin-left: -1px;
}


/*event-form.php*/
.custom-combobox-input {
    width: 240px
}

.ui-autocomplete {
    text-align: left;
    max-height: 350px;
    overflow: auto
}

/*taining.php*/

.stats div {
    display: inline-block;
}

.stats .statsTable {
    width: 40%;
}

.stats .statsChart {
    width: 55%;
    vertical-align: top;
    padding-left: 3%;
}

.inductionVideoForm .videoLink {
    position: relative;
    display: block;
    clear: both;
    margin-left: 458px;
    top: -14px;
}

#formAccident.inductionVideoForm .labelvlong {
    width: 435px;
}

.inductionVideoForm input[type=file] {
    margin-left: 14px;
}

#formAccident select#location {
    margin: 0 0 15px 0;
    width: auto
}


/*
@font-face {
    font-family: 'password';
    src: url('password.eot');
    src: url('password?#iefix') format('embedded-opentype'),
        url('password.ttf') format('truetype'),
        url('password.svg#password') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
.password {
    font-family: 'password';
}

#formAccident .signature {
    left: 344px;
    position: relative;
    top: -50px;
    display: block;
    height: 50px
}

#formAccident .signature img {
    height: 100px;
}



.timepicker .ui-datepicker-header,
.timepicker .ui-datepicker-calendar {
    display: none;
}

.accordion .ui-accordion-header {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 19px
}


.accordion .ui-accordion-content {
    padding: 0
}

.accordion .accordion .ui-accordion-header {
    margin: 0;
    border: 0;
    border-radius: 0;
    font-size: 100%
}

.accordion .accordion .ui-accordion-content {
    padding: 1em 2.2em;
    border: none;
    border-radius: 0
}

.accordion .ui-accordion-content a:visited,
.accordion .ui-accordion-content a {
    color: #696969
}

.accordion .ui-accordion-content a:hover {
    color: #234792
}

.buorg {
    display: none
}

.monthDay .ui-datepicker-year {
    display: none
}

.ui-spinner {
    height: 20px !important;
    width: 100px
}

.ui-spinner-button {
    height: 9px !important
}

.ui-state-disabled {
    opacity: 0.6;
}


.trainingmatrix {
    overflow-x: auto;
    margin-top: 10px;
    overflow-y: visible;
    padding-bottom: 1px;
    position: relative;
}

.trainingmatrix table {
    border-collapse: collapse;
}

.matrixBody {
    white-space: nowrap;

    tr.row.typeRow th.rowName {
        padding: 0px !important;
        height: 12px !important;
        width: auto !important;
    }
}

.matrixEmployee {
    white-space: nowrap;
    padding: 0 5px;
}

.matrixBody .typeRow td {
    border: 1px solid;
    text-align: center;
    font-size: 9px;
}

.matrixBody .typeRow .rowName {
    border: none;
    text-align: left;
    font-size: inherit;
}

.matrixBody .row {
    height: 14px
}

.row.headerRow {
    height: 18px;
}

.row.headerRow th {
    padding: 3px 0
}

.row.typeRow td[b=g] {
    background-color: #c9c9c9
}

.matrixBody .row.typeRow .rowName {
    left: 0px !important;
}


#calendar .fc-row:last-child .fc-bg table {
    border-bottom: 0px none
}

#calendar .ui-state-highlight.fc-day {
    border: 1px solid #aaa;
    background: #d1d1f9
}

#holidayApproveForm input {
    margin: -2px 1px 0 5px;
    position: relative;
    top: 3px;
}

#holidayApproveForm textarea {
    width: 90%
}

#holidayApproveForm td {
    vertical-align: top
}

#holidayApproveForm th {
    padding-bottom: 40px;
}



table.ui-datepicker-calendar {
    border-collapse: separate;
}

.ui-datepicker-calendar td {
    border: 1px solid transparent;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-highlight a {
    background: #3383bb none;
    color: white;
}

.ui-state-highlight {
    border: 1px solid #c6c6c6
}

.calendarCon {
    position: relative;
    margin: 35px 0;
}


.calendarCon .calendarInner {
    width: 70%;
    position: relative;
    margin: auto;
    left: 0;
    right: 0
}

.calendarCon .calendarInner .key {
    width: 100px;
    float: left;
    margin: 0 10px 10px 10px;
}

#formAccident .vertical Label:not(.label) {
    width: auto;
    padding: 0;
}

#formAccident .vertical dv {
    margin-bottom: 10px
}

#formAccident .vertical input {
    margin-top: 0px
}

#formAccident .nolabel {
    margin-left: 160px;
    clear: both;
}

#formAccident .nolabellong {
    margin-left: 250px;
    clear: both;
}

#formAccident #showwhat [type=checkbox] {
    float: none
}

.arrows a {
    padding: 0 6px;
    font-size: 12px
}

form.dropzone {
    clear: both;
}


#files>a:last-child {
    margin-bottom: 20px;
}

#files>a {
    display: inline-block;
    margin-left: 25px;
    margin-top: 3px;
    overflow: hidden;
    vertical-align: top;
    white-space: nowrap;
    width: 30%;
}

#period .between,
#period .between a {
    background: #ccc;
}

#period .half,
#period .half a {
    background: #62b2ea;
}

#formAccident .holidayMessages {
    height: 80px;
}


#formAccident .messagecon {
    margin-bottom: 10px;
    padding: 5px 15px 5px 4px;
    min-width: 250px;
    float: left;
    clear: both;
}

#formAccident .info {
    background: rgba(0, 0, 0, 0) url("customtheme/images/ui-icons_1d5a85_256x240.png") repeat scroll -15px -144px;
    display: inline;
}

/* === Pagination === */

.pagination {
    list-style: none;
    padding: 0;
    margin: 0;

    a:visited{
        color:black;
    }
}

.pagination li {
    padding: 5px 9px;
    border: 1px solid #CCC;
    display: inline-block;
}

/* === Planner === */
.accordion table {
    width: 100%;
    border: 1px solid #CCC;
    border-collapse: collapse;
    border-spacing: 1px;
}

.accordion table td,
.accordion table th {
    padding: 6px;
}

.accordion table tr {
    border-bottom: 1px solid #CCC;
}

.accordion td,
.accordion th {
    border-right: 1px solid #CCC;
}

.accordion table th,
.accordion tfoot td {
    background: #EEE;
}


.secondary-btn {

    @include button;
    background-color: #2f7ed8 !important;
    color: #fff !important;
}


.handstable {
    border-collapse: collapse !important;

    td,
    th {
        border: 1px solid black;
        font-size: 12px;
        padding: 1vh;
        margin: 100vh;
    }

    th {
        background-color: lightgray;
        text-align: center;
    }
}

.handsradio {
    text-align: center;
}

.radio-green{
    color: green;

    input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: green;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
}

.radio-red{
    color: red;

    input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: red;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
}

.radio-grey{
    color: grey;

    input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: grey;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
}

.section-block {
    border: 1px solid gray;
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
    position:relative;

    .title-block {
        position:absolute;
        top: -25px;
        left: 10px;
    }
    
    .section-title{
        background-color: white;
        padding: 0px 10px 0px 10px;   
    }

    .label-input-container{
        margin-bottom: 15px;
    }

    label {
        vertical-align: top;
        display: inline-block !important;
        width: 150px;
    } 
    
    input, select, textarea {
        // min-width: 500px;
        // max-width: auto !important;
    }

    textarea {
        min-height: 50px;
    }

}
.TDResults {
    a, a:visited {
        color: white;
    }
}

.statsTable{
    a, a:visited {
        color: #18537d;
    }
}